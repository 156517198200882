body {
  overflow-x: hidden;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.react-pdf__Page__annotations.annotationLayer {
  display: none;
}
/* .tox.tox-silver-sink {
  display: none;
}  */

#container {
  width: auto !important;
  max-width: none !important;
  min-height: 937px !important;
}
#container_carga {
  width: auto !important;
  max-width: none !important;
  height: 100vh !important;
}
#container_curso {
  width: 100% !important;
  max-width: none !important;
  height: 100vh !important;
  overflow-y: auto;
  padding: 0 !important;
  overflow-x: hidden;
}
#container_perfil {
  width: 100% !important;
  max-width: none !important;
  height: 100vh !important;
  overflow-y: auto;
}
#cabecera_inicio {
  padding-top: 40px;
  padding-left: 40px;
}
#texto_inicio {
  display: inline-block;
  width: fit-content;
  margin: 0px auto;
  /* padding-right: 250px; */
  vertical-align: middle;
  padding-top: 40px;
}
#parrafo_inicio_pri {
  font-size: 30px;
  font-weight: 500;
  color: #555a53;
  text-align: right;
  margin: 0;
}
#parrafo_inicio_seg {
  color: #ee519c;
  font-weight: 700;
  font-size: 50px;
  margin: 0;
  font-style: italic;
}
#seleccion_inicio {
  text-align: center;
}
#seleccion_inicio > div img:hover {
  transform: scale(1.03);
  cursor: pointer;
}

.MuiTypography-root {
  font-family: unset !important;
}

.tipo_titulo_1 {
  color: #b04291 !important;
  font-style: italic !important;
  font-weight: 700 !important;
  font-size: 30px !important;
}

.tipo_titulo_curso {
  color: #b04291;
  font-style: italic !important;
  font-weight: 700 !important;
  font-size: 40px !important;
}
.tipo_titulo_fecha {
  color: #b04291;
  font-style: italic !important;
  font-weight: 300 !important;
  font-size: 30px !important;
}
.tipo_titulo_seccion {
  color: #b04291;
  font-style: italic !important;
  font-weight: 700 !important;
  font-size: 24px !important;
}
.tipo_titulo_2 {
  color: #b04291;
  font-style: italic !important;
  font-weight: 700 !important;
  font-size: 16px !important;
}
.tipo_titulo_3 {
  color: #b04291;
  font-style: italic !important;
  font-weight: 400 !important;
  font-size: 22px !important;
}
.tipo_texto {
  color: #555a53 !important;
  font-style: italic !important;
  font-weight: 500 !important;
  font-size: 14px !important;
}
.tipo_texto_2 {
  color: #555a53 !important;
  font-style: italic !important;
  font-weight: 500 !important;
  font-size: 19px !important;
}
.tipo_texto_3 {
  color: #555a53 !important;
  font-style: italic !important;
  font-weight: 400 !important;
  font-size: 12px !important;
}
.tipo_texto_4 {
  color: #555a53 !important;
  font-style: italic !important;
  font-weight: 600 !important;
  font-size: 18px !important;
}
.tipo_texto_5 {
  color: #555a53 !important;
  font-style: italic !important;
  font-weight: 600 !important;
  font-size: 12px !important;
}
.tipo_texto_6 {
  color: #555a53 !important;
  font-style: italic !important;
  font-size: 12px !important;
  font-weight: 600 !important;
}

.titulo_popup_links {
  color: #5b5b5f !important;
  font-style: italic !important;
  font-weight: 600 !important;
  font-size: 32px !important;
}
.titulo_popup_links:hover {
  color: #be0088 !important;
}
.texto_popup_links {
  color: #9e9e9e !important;
  font-style: italic !important;
  font-size: 20px !important;
  font-weight: 400 !important;
}

.titulo_popup_not {
  color: #be0088 !important;
  font-style: italic !important;
  font-weight: 600 !important;
  font-size: 30px !important;
}
.titulo_popup_not.leido {
  color: #b9c1c4 !important;
  font-style: italic !important;
  font-weight: 600 !important;
  font-size: 30px !important;
}

.titulo_popup_fav {
  color: #5b5b5f !important;
  font-style: italic !important;
  font-weight: 600 !important;
  font-size: 31px !important;
  margin-bottom: 10px;
}
.texto_popup_fav {
  color: #be0088 !important;
  font-style: italic !important;
  font-weight: 500 !important;
  font-size: 20px !important;
}
.titulo_popup_buscador {
  color: #5b5b5f !important;
  font-style: italic !important;
  font-weight: 600 !important;
  font-size: 20px;
  margin-bottom: 10px;
}
.texto_popup_buscador {
  color: #be0088 !important;
  font-style: italic !important;
  font-weight: 500 !important;
  font-size: 17px !important;
}
.titulo_popup_seguimiento {
  color: #35378a;
  font-style: italic !important;
  font-weight: 600 !important;
  font-size: 22px;
}
.link_puntuable {
  color: #b04291;
  font-style: italic;
  font-weight: 600;
  font-size: 18px;
  }

.sinComentarios {
  color: #555a53;
  font-size: 14px;
  font-style: italic;
}
.desc_ponente {
  margin-top: 0px;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.imgCursosSlider {
  padding: 5px 5px 0px;
}

.dividerRanking {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(189, 100, 165, 1) 15%,
    rgba(189, 100, 165, 1) 50%,
    rgba(189, 100, 165, 1) 85%,
    rgba(255, 255, 255, 1) 100%
  );
  border: none !important;
  width: 2px;
}

.right-to-left {
  border-width: 3px;
  border-style: solid;
  -webkit-border-image: -webkit-gradient(
      linear,
      0 100%,
      0 0,
      from(white),
      to(rgba(0, 0, 0, 0))
    )
    1 1%;
  -webkit-border-image: -webkit-linear-gradient(bottom, white, rgba(0, 0, 0, 0))
    1 1%;
  -moz-border-image: -moz-linear-gradient(bottom, white, rgba(0, 0, 0, 0)) 1 1%;
  -o-border-image: -o-linear-gradient(bottom, white, rgba(0, 0, 0, 0)) 1 1%;
  border-image: linear-gradient(to left, white, rgba(255, 255, 255, 0)) 1 1%;
  border-image-width: 2;
}

.left-to-right {
  border-width: 3px;
  border-style: solid;
  -webkit-border-image: -webkit-gradient(
      linear,
      0 100%,
      0 0,
      from(white),
      to(rgba(0, 0, 0, 0))
    )
    1 1%;
  -webkit-border-image: -webkit-linear-gradient(bottom, white, rgba(0, 0, 0, 0))
    1 1%;
  -moz-border-image: -moz-linear-gradient(bottom, white, rgba(0, 0, 0, 0)) 1 1%;
  -o-border-image: -o-linear-gradient(bottom, white, rgba(0, 0, 0, 0)) 1 1%;
  border-image: linear-gradient(to right, white, rgba(255, 255, 255, 0)) 1 1%;
  border-image-width: 2;
}

.caja_translucida {
  padding: 10px 30px;
  border-radius: 25px;
  background: linear-gradient(
    125deg,
    rgba(208, 226, 232, 0.7) 0%,
    rgba(220, 237, 244, 0.7) 40%,
    rgba(210, 230, 239, 0.7) 40%,
    rgba(209, 229, 238, 0.7) 62%,
    rgba(214, 231, 239, 0.7) 62%,
    rgba(211, 229, 237, 0.7) 67%,
    rgba(204, 224, 233, 0.7) 67%,
    rgba(201, 221, 230, 0.7) 71%,
    rgba(211, 229, 237, 0.7) 71%,
    rgba(205, 225, 233, 0.7) 100%
  );
  border: 1px solid rgb(255, 255, 255);
}

.ver_mas {
  color: #b04291 !important;
  font-style: italic !important;
  font-weight: 600 !important;
  font-size: 13px !important;
  margin-top: 10px !important;
  margin-left: 15px !important;
  text-align: right;
  cursor: pointer;
  display: inline-block;
}

.cursoCuadro {
  display: none;
  border: 1px solid rgba(255, 255, 255, 0.8);
  background-color: rgba(255, 255, 255, 0.2);
  padding: 15px 30px;
}

div.cursoCuadrosSel {
  display: inline-block;
  /* width: calc(100% - 100px); */
  width: calc(100% - 0px);
  vertical-align: middle;
  background: linear-gradient(
    125deg,
    rgba(208, 226, 232, 0.7) 0%,
    rgba(220, 237, 244, 0.7) 40%,
    rgba(210, 230, 239, 0.7) 40%,
    rgba(209, 229, 238, 0.7) 62%,
    rgba(214, 231, 239, 0.7) 62%,
    rgba(211, 229, 237, 0.7) 67%,
    rgba(204, 224, 233, 0.7) 67%,
    rgba(201, 221, 230, 0.7) 71%,
    rgba(211, 229, 237, 0.7) 71%,
    rgba(205, 225, 233, 0.7) 100%
  );
  box-shadow: none;
}

div.buscador > div {
  border-radius: 56px;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.6) 0%,
    rgba(203, 222, 244, 0) 42%,
    rgba(255, 255, 255, 0.6) 100%
  );
}
/* div.buscador > label {
  color: #fff !important;
}  */
.imgBmsCabecera {
  max-width: 300px;
  width: 40%;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  display: block;
  top: 20%;
  min-width: 200px;
}
div.buscador > div:hover > fieldset {
  border-color: #fff !important;
}

div.buscador > div > fieldset {
  border: 1px solid #fff;
}
div.buscador > div > fieldset:hover,
div.buscador > div > fieldset:focus {
  border-color: #fff !important;
}

.cajaLinks {
  background: rgb(239, 239, 239);
  background: linear-gradient(
    90deg,
    rgba(239, 239, 239, 0) 0%,
    rgba(246, 246, 246, 1) 50%,
    rgba(255, 255, 255, 1) 100%
  );
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-top-left-radius: 45px;
  border-bottom-left-radius: 45px;
  text-align: right;
  padding: 30px 50px 20px 0px;
  box-sizing: border-box;
  border-right: none;
}
.cajaRanking {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(246, 246, 246, 1) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-top-right-radius: 45px;
  border-bottom-right-radius: 45px;
  padding: 30px 10px 20px 30px;
  box-sizing: border-box;
  border-left: none;
}

#fondoAvatar {
  margin-right: 0px !important;
  position: relative;
}
#fondoAvatar::after {
  background-image: url("./Assets/Images/fondo-avatar.png");
  display: block;
  width: 110%;
  height: 200px;
  content: "";
  background-repeat: no-repeat;
  background-size: contain;
  max-width: 501px;
  position: absolute;
  top: 35px;
  right: 0px;
  transform: rotatey(180deg);
}

#fondoPerfil {
  margin-left: 0px !important;
  position: relative;
}
#fondoPerfil::after {
  background-image: url("./Assets/Images/fondo-avatar.png");
  display: block;
  width: 110%;
  height: 200px;
  content: "";
  background-repeat: no-repeat;
  background-size: contain;
  max-width: 501px;
  position: absolute;
  top: 25px;
}

.title_editar {
  font-size: 20px;
  color: #6a6a6a;
  font-style: italic;
  font-weight: 400;
  background: rgb(186, 197, 208);
  background: -moz-linear-gradient(
    90deg,
    rgba(186, 197, 208, 1) 0%,
    rgba(186, 197, 208, 1) 50%,
    rgba(255, 255, 255, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(186, 197, 208, 1) 0%,
    rgba(186, 197, 208, 1) 50%,
    rgba(255, 255, 255, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(186, 197, 208, 1) 0%,
    rgba(186, 197, 208, 1) 50%,
    rgba(255, 255, 255, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#bac5d0",endColorstr="#ffffff",GradientType=1);
  padding: 5px 0px 5px 20px;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}
.img_avatar {
  cursor: pointer;
}
.avatar_sel {
  border: 4px solid #e70072;
  padding: 0px 5px 5px;
}
div.MuiDialog-paper {
  border-radius: 25px !important;
}
/*SLIDER DE VIDEOS*/
div.react-3d-carousel .slider-container .slider-content {
  width: 75% !important;
}
div.react-3d-carousel
  .slider-container
  .slider-content
  .slider-single
  .slider-single-content {
  box-shadow: none !important;
}
div.react-3d-carousel .slider-container .slider-right {
  left: 101%;
  cursor: default;
}
div.react-3d-carousel .slider-container .slider-right div {
  border: none !important;
  background: rgba(0, 0, 0, 0.5);
  cursor: pointer;
}
div.react-3d-carousel .slider-container .slider-left {
  right: 101%;
  cursor: default;
}
div.react-3d-carousel .slider-container .slider-left div {
  border: none !important;
  background: rgba(0, 0, 0, 0.5);
  cursor: pointer;
}
div.react-3d-carousel
  .slider-container
  .slider-content
  .slider-single.preactive
  .slider-single-content,
div.react-3d-carousel
  .slider-container
  .slider-content
  .slider-single.proactive
  .slider-single-content {
  opacity: 0.6;
}
.slider-single.active .content_videoplayer {
  cursor: pointer;
}
.content_videoplayer::after {
  content: url("./Assets/Images/play_circle_v2.png");
  position: absolute;
  top: calc(50% - 48px);
  left: calc(50% - 48px);
}

/* PERFIL */
.infoPerfil {
  position: absolute;
  flex: 1;
  align-self: center;
}
div.cajaCursosSlider > div.imgCursosSlider:not(.notCurso):hover {
  transform: scale(1.1);
  cursor: pointer;
}
.tooltipCursoSlider {
  display: none;
  position: absolute;
  background-color: #bcd2ea;
  padding: 5px 10px;
  border-radius: 15px;
  border: 1px solid #63778f;
  left: 50%;
  right: 0;
  color: #293d66;
  font-size: 11px;
  font-weight: 600;
  font-style: italic;
  width: max-content;
  transform: translateX(-50%);
  top: -35px;
}
div.cajaCursosSlider > div.imgCursosSlider:hover + .tooltipCursoSlider {
  display: block;
}
.blackwhite {
  filter: grayscale(100%);
}

/* REPONSIVE -> MENOS DE 1280PX */
.fondoBmsCabecera {
  max-width: 890px;
  width: 100%;
  margin: 0 auto;
  display: block;
}
.colConFondo {
  background-image: url("./Assets/svg/fondo.svg");
  background-size: cover;
  position: absolute;
  top: 20px;
  bottom: 25px;
  right: 0;
  left: 0;
}

@media (max-width: 1279px) {
  div.cursoCuadrosSel {
    width: calc(100% - 50px);
  }
  div.react-3d-carousel .slider-container .slider-content {
    width: 60% !important;
  }
  #fondoPerfil::after {
    height: 84%;
    bottom: 0px;
    top: 41px;
  }
  #fondoAvatar::after {
    height: 100%;
    top: auto;
    right: 0px;
    bottom: -8px;
  }
}
@media (max-width: 991px) {
  #fondoPerfil::after {
    height: 84%;
    bottom: 15px;
  }
  #fondoAvatar::after {
    height: 84%;
    top: auto;
    right: 0px;
    bottom: 20px;
    background-position: right;
  }
}

@media (max-width: 600px) {
  #fondoPerfil::after {
    top: 20px;
  }
  .titulo_popup_fav {
    font-size: 20px !important;
  }
  .texto_popup_fav {
    font-size: 16px !important;
  }
  .titulo_popup_not {
    font-size: 20px !important;
  }
  .titulo_popup_not.leido {
    font-size: 20px !important;
  }
  .titulo_popup_links {
    font-size: 24px !important;
  }
  .texto_popup_links {
    font-size: 16px !important;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
